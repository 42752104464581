import { QueryFallback, RestrictedSection } from '@/components'
import {
    useMutationUpdateByIdCounterpartyAccount,
    useNavigationRoutes,
    useQueryFindByIdCounterpartyAccount
} from '@/hooks'
import { PageLayout } from '@/layouts'
import { CounterpartyAccountHeader } from '@/pages'
import { updateUUIDRelativeActionPathname } from '@/providers'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { Box } from '@chakra-ui/react'
import { ApiObjectTypeSchema } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { CounterpartyAccountEditForm } from './@components'

export const CounterpartyAccountEditPage: React.FC = () => {
    const { uuid } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const { relativePaths } = useNavigationRoutes()
    const query = useQueryFindByIdCounterpartyAccount(uuid)
    const mutation = useMutationUpdateByIdCounterpartyAccount(uuid, {
        onSuccess() {
            const { ACCOUNTS } = relativePaths
            const path = updateUUIDRelativeActionPathname(location.pathname, ACCOUNTS.DETAILS)

            navigate(path)
        }
    })

    return (
        <PageLayout className="CounterpartyAccountEdit">
            <CounterpartyAccountHeader />
            <RestrictedSection
                feature={ENTITY_FEATURE.COUNTERPARTY}
                permission={PERMISSION.COUNTERPARTIES_EDIT_COUNTERPARTY_ACCOUNTS}>
                <Box marginTop="8px">
                    <QueryFallback
                        objectType={ApiObjectTypeSchema.enum.counterparty_account}
                        isLoading={query.isLoading}
                        isSuccess={query.isSuccess}
                        isError={query.isError || isEmpty(query?.data)}
                        isDataEmpty={isEmpty(query?.data)}>
                        <CounterpartyAccountEditForm
                            onSubmit={mutation.mutate}
                            disabled={mutation.isPending}
                            loading={mutation.isPending}
                            data={query.data}
                        />
                    </QueryFallback>
                </Box>
            </RestrictedSection>
        </PageLayout>
    )
}
