import { QueryFallback, RestrictedSection, Table, TableHeader, useTableRowNavigation } from '@/components'
import { useNavigationRoutes, useQueryParams } from '@/hooks'
import { useQueryFindAllVirtualAccounts } from '@/hooks/api/virtualAccounts'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import {
    ApiObjectTypeSchema,
    VirtualAccount,
    VirtualAccountsServiceFindAllQueryOptions
} from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import {
    VIRTUAL_ACCOUNT_DOCUMENTATION_LINK,
    VIRTUAL_ACCOUNT_FILTERS,
    VIRTUAL_ACCOUNT_TABLE_COLUMN_CUSTOMIZERS,
    VIRTUAL_ACCOUNT_TABLE_STATIC_STATE
} from './VirtualAccounts.page.const'

export const VirtualAccountsPage: React.FC = () => {
    const intl = useIntl()
    const { relativePaths } = useNavigationRoutes()
    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.counterparties.virtual_accounts.title' }), [intl])
    const { search, filterBy } = useQueryParams<VirtualAccountsServiceFindAllQueryOptions>('search', {
        filterBy: { configuration: { filters: VIRTUAL_ACCOUNT_FILTERS } }
    })
    const query = useQueryFindAllVirtualAccounts({ ...search.parsedState, ...filterBy.parsedState })
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])

    const onTableRowClick = useTableRowNavigation<VirtualAccount>('id', relativePaths.COUNTERPARTIES.DETAILS)

    return (
        <PageLayout>
            <PageHeaderLayout title={title} showBackButton={false} />
            <RestrictedSection
                feature={ENTITY_FEATURE.COUNTERPARTY}
                permission={PERMISSION.COUNTERPARTIES_VIEW_VIRTUAL_ACCOUNTS}>
                <TableHeader<VirtualAccountsServiceFindAllQueryOptions>
                    objectType={ApiObjectTypeExtendedSchema.enum.virtual_account}
                    isLoading={query.isLoading}
                    search={search}
                    filterBy={filterBy}
                />
                <QueryFallback
                    objectType={ApiObjectTypeSchema.enum.virtual_account}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError}
                    isDataEmpty={isDataEmpty}
                    isFilteringOrSearching={search.isActive || filterBy.isActive}
                    learnMoreLinkUrl={VIRTUAL_ACCOUNT_DOCUMENTATION_LINK}>
                    <Table<VirtualAccount>
                        data={data}
                        onRowClick={onTableRowClick}
                        onScrollToBottom={query.fetchNextPage}
                        isLoading={query.hasNextPage}
                        state={VIRTUAL_ACCOUNT_TABLE_STATIC_STATE}
                        columnCustomizers={VIRTUAL_ACCOUNT_TABLE_COLUMN_CUSTOMIZERS}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
