import { useAuth, useConfiguration, useServices } from '@/hooks'
import { Bank, BanksServiceFindAllQueryOptions, filterUserAccessQueryParams } from '@/services'
import type { UndefinedInitialDataInfiniteOptions } from '@tanstack/react-query'
import { useInfiniteQuery } from '@tanstack/react-query'
import { List } from '@webapps/numeral-ui-core'
import { paginationQueryOptions } from '../paginationQueryOptions.const'

export function useQueryFindAllBanks(
    params?: Partial<BanksServiceFindAllQueryOptions>,
    options?: Partial<UndefinedInitialDataInfiniteOptions<List<Bank>, Error>>
) {
    const { banksService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { pagination } = useConfiguration()
    const isQueryEnabled = isUserLoggedIn && options?.enabled
    const paramsWithUserAccess: typeof params = {
        limit: pagination.limit,
        ...params,
        ...filterUserAccessQueryParams(selectedUserAccess)
    }

    return useInfiniteQuery<List<Bank>, Error>({
        ...options,
        enabled: isQueryEnabled,
        initialPageParam: undefined,
        queryKey: [banksService.url, paramsWithUserAccess],
        queryFn({ signal, pageParam }) {
            return banksService.findAll({ ...paramsWithUserAccess, ...(pageParam as typeof params) }, { signal })
        },
        getNextPageParam(lastPage) {
            return paginationQueryOptions.getNextPageParam(lastPage, pagination.limit)
        }
    })
}
