import { EntityDetail, QueryFallback, RestrictedSection } from '@/components'
import { If } from '@/components/@misc'
import { usePermissions, useQueryFindByIdCounterpartyAccount } from '@/hooks'
import { PageLayout } from '@/layouts'
import { getCustomFieldsRowCustomizers } from '@/pages'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { ApiObjectTypeSchema, CounterpartyAccount } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { CounterpartyAccountApproval } from './@components'
import {
    COUNTERPARTY_ACCOUNT_DETAILS_ROW_CUSTOMIZERS,
    COUNTERPARTY_ACCOUNT_DETAILS_ROWS
} from './CounterpartyAccountDetails.page.const'

export const CounterpartyAccountDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const { hasPermission } = usePermissions()
    const query = useQueryFindByIdCounterpartyAccount(uuid)
    const rowCustomizers = useMemo(() => {
        return {
            ...COUNTERPARTY_ACCOUNT_DETAILS_ROW_CUSTOMIZERS,
            custom_fields: getCustomFieldsRowCustomizers(
                query.data,
                PERMISSION.COUNTERPARTIES_EDIT_COUNTERPARTY_ACCOUNTS
            )
        }
    }, [query])

    return (
        <PageLayout>
            <RestrictedSection
                feature={ENTITY_FEATURE.COUNTERPARTY}
                permission={PERMISSION.COUNTERPARTIES_VIEW_COUNTERPARTY_ACCOUNTS}>
                <QueryFallback
                    objectType={ApiObjectTypeSchema.enum.counterparty_account}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isEmpty(query?.data)}
                    isDataEmpty={isEmpty(query?.data)}>
                    <EntityDetail<CounterpartyAccount>
                        data={query.data}
                        rows={COUNTERPARTY_ACCOUNT_DETAILS_ROWS}
                        rowCustomizers={rowCustomizers}
                    />
                    <If condition={hasPermission(PERMISSION.COUNTERPARTIES_APPROVE_COUNTERPARTY_ACCOUNTS)}>
                        <CounterpartyAccountApproval data={query.data} />
                    </If>
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
