import { If, Select, useSearchQuerySelect } from '@/components/@misc'
import { anythingValidator } from '@/utils/@validators'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { useField } from 'formik'
import React, { useCallback, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Bank } from '@/services'
import { useQueryFindAllBanks } from '@/hooks'
import { queryDataAggregation } from '@/utils'
import { BankInputProps } from './BankInput.types'
import { getFormSelectPlaceholderByReactQueryState } from '@/components'

export const BankInput: React.FC<BankInputProps> = ({
    name,
    value,
    searchOnMount = false,
    isRequired,
    isDisabled = false,
    customLabelKey,
    customLabelValues,
    customPlaceholderKey,
    customPlaceholderValues,
    isPlaceholderDisplayed = false,
    isLabelDisplayed = true,
    validator = anythingValidator,
    ...inputProps
}) => {
    const intl = useIntl()
    const [field, meta, helpers] = useField({
        name,
        value,
        validate: validator?.(intl, isRequired)
    })
    const { enabled, search, limit, inputText, onInputChange, onFocus } = useSearchQuerySelect(searchOnMount)
    const query = useQueryFindAllBanks(
        { search, limit },
        {
            enabled: !isDisabled && enabled
        }
    )
    const data = useMemo<Bank[]>(() => {
        return queryDataAggregation(query.data)
    }, [query])
    const label = useMemo<string>(() => {
        const labelKey = customLabelKey || `app.common.form.input.${name}.label`
        return intl.formatMessage({ id: labelKey }, customLabelValues)
    }, [name, customLabelKey, intl, customLabelValues])
    const placeholder = useMemo<string | undefined>(() => {
        if (customPlaceholderKey) {
            return intl.formatMessage({ id: customPlaceholderKey }, customPlaceholderValues)
        }
        return getFormSelectPlaceholderByReactQueryState(intl, query, isPlaceholderDisplayed)
    }, [customPlaceholderKey, customPlaceholderValues, intl, query, isPlaceholderDisplayed])

    const onChange = useCallback(
        (value: string) => {
            inputProps.onChange?.(value)
            helpers.setValue(value)
        },
        [helpers, inputProps.onChange]
    )
    const isInvalid = meta.touched && !!meta.error

    return (
        <FormControl key={name} isInvalid={isInvalid}>
            <If condition={isLabelDisplayed}>
                <FormLabel htmlFor={name}>
                    <FormattedMessage id={label} />
                </FormLabel>
            </If>
            <Select<Bank>
                {...inputProps}
                {...field}
                id={name}
                options={data}
                placeholder={placeholder}
                isInvalid={isInvalid}
                isRequired={isRequired}
                isDisabled={isDisabled}
                isLoading={query.isLoading}
                isClearable={true}
                inputValue={inputText}
                onChange={onChange}
                onFocus={onFocus}
                onInputChange={onInputChange}
                getOptionValue={(item) => item.name}
                getOptionLabel={(item) => item.name}
            />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    )
}
