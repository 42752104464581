import {
    QueryFallback,
    RestrictedSection,
    Table,
    TableHeader,
    TableHeaderActionProps,
    useTableRowNavigation
} from '@/components'
import { SupportEmailMailtoLink } from '@/components/@misc'
import {
    useCustomFieldFilters,
    useMutationExportCounterpartyAccounts,
    useNavigationRoutes,
    usePermissions,
    useQueryFindAllCounterpartyAccounts,
    useQueryParams,
    useToasts
} from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { QuickFilterProvider, TOAST_VARIANTS } from '@/providers'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import {
    ApiObjectTypeSchema,
    CounterpartyAccount,
    CounterpartyAccountsServiceFindAllQueryOptions
} from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import {
    COUNTERPARTY_ACCOUNT_DOCUMENTATION_LINK,
    COUNTERPARTY_ACCOUNT_FILTERS,
    COUNTERPARTY_ACCOUNT_QUICK_FILTERS,
    COUNTERPARTY_ACCOUNT_TABLE_COLUMN_CUSTOMIZERS,
    COUNTERPARTY_ACCOUNT_TABLE_STATIC_STATE
} from './CounterpartyAccounts.page.const'

export const CounterpartyAccountsPage: React.FC = () => {
    const intl = useIntl()
    const { hasPermission } = usePermissions()
    const navigate = useNavigate()
    const { onAdd } = useToasts()
    const { relativePaths } = useNavigationRoutes()
    const title = useMemo<string>(
        () => intl.formatMessage({ id: 'app.counterparties.counterparty_accounts.title' }),
        [intl]
    )
    const filters = useCustomFieldFilters(
        ApiObjectTypeExtendedSchema.enum.counterparty_account,
        COUNTERPARTY_ACCOUNT_FILTERS
    )
    const { search, filterBy } = useQueryParams<CounterpartyAccountsServiceFindAllQueryOptions>('search', {
        filterBy: {
            configuration: {
                filters,
                quickFilters: COUNTERPARTY_ACCOUNT_QUICK_FILTERS
            }
        }
    })

    const query = useQueryFindAllCounterpartyAccounts({ ...search.parsedState, ...filterBy.parsedState })
    const mutationExport = useMutationExportCounterpartyAccounts({
        onSuccess(response) {
            onAdd({
                variant: TOAST_VARIANTS.INFO,
                status: 'info',
                title: <FormattedMessage id="app.common.export.success.toast.title" />,
                description: <FormattedMessage id="app.common.export.success.toast.description" />
            })
        },
        onError(error) {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.common.export.error.toast.title" />,
                description: (
                    <FormattedMessage
                        id="app.common.export.error.toast.description"
                        values={{ supportEmailMailtoLink: <SupportEmailMailtoLink /> }}
                    />
                )
            })
        }
    })

    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])

    const emptyStateButtonProps = useMemo(() => {
        if (!hasPermission(PERMISSION.COUNTERPARTIES_CREATE_COUNTERPARTY_ACCOUNTS)) {
            return
        }

        return {
            children: intl.formatMessage({
                id: 'app.counterparties.counterparty_accounts.create.form.actions.submit.label'
            }),
            onClick() {
                navigate(relativePaths.NEW)
            }
        }
    }, [hasPermission, intl, navigate, relativePaths.NEW])

    const actions = useMemo<TableHeaderActionProps[]>(() => {
        const actions: TableHeaderActionProps[] = []

        actions.push({
            onClick() {
                mutationExport.mutate({ search_parameters: { ...search.parsedState, ...filterBy.parsedState } })
            },
            children: intl.formatMessage({ id: 'app.common.actions.export' }),
            isDisabled: isDataEmpty || mutationExport.isPending
        })

        if (hasPermission(PERMISSION.COUNTERPARTIES_CREATE_COUNTERPARTY_ACCOUNTS)) {
            actions.push({
                onClick() {
                    navigate(relativePaths.NEW)
                },
                children: intl.formatMessage({
                    id: 'app.counterparties.counterparty_accounts.create.form.actions.submit.label'
                })
            })
        }

        return actions
    }, [
        intl,
        isDataEmpty,
        mutationExport,
        hasPermission,
        search.parsedState,
        filterBy.parsedState,
        navigate,
        relativePaths.NEW
    ])

    const onTableRowClick = useTableRowNavigation<CounterpartyAccount>('id', relativePaths.COUNTERPARTIES.DETAILS)

    return (
        <PageLayout>
            <PageHeaderLayout title={title} showBackButton={false} />
            <RestrictedSection
                feature={ENTITY_FEATURE.COUNTERPARTY}
                permission={PERMISSION.COUNTERPARTIES_VIEW_COUNTERPARTY_ACCOUNTS}>
                <QuickFilterProvider<CounterpartyAccountsServiceFindAllQueryOptions> filterBy={filterBy}>
                    <TableHeader<CounterpartyAccountsServiceFindAllQueryOptions>
                        objectType={ApiObjectTypeExtendedSchema.enum.counterparty_account}
                        isLoading={query.isLoading}
                        actions={actions}
                        search={search}
                        filterBy={filterBy}
                    />
                    <QueryFallback
                        objectType={ApiObjectTypeSchema.enum.counterparty_account}
                        isLoading={query.isLoading}
                        isSuccess={query.isSuccess}
                        isError={query.isError}
                        isDataEmpty={isDataEmpty}
                        isFilteringOrSearching={search.isActive || filterBy.isActive}
                        learnMoreLinkUrl={COUNTERPARTY_ACCOUNT_DOCUMENTATION_LINK}
                        emptyStateButtonProps={emptyStateButtonProps}>
                        <Table<CounterpartyAccount>
                            data={data}
                            onRowClick={onTableRowClick}
                            onScrollToBottom={query.fetchNextPage}
                            isLoading={query.hasNextPage}
                            state={COUNTERPARTY_ACCOUNT_TABLE_STATIC_STATE}
                            columnCustomizers={COUNTERPARTY_ACCOUNT_TABLE_COLUMN_CUSTOMIZERS}
                        />
                    </QueryFallback>
                </QuickFilterProvider>
            </RestrictedSection>
        </PageLayout>
    )
}
