import { ChartBarVerticalStack, transformWidgetTimeSeriesToChartData } from '@/components/@charts'
import React, { memo, useMemo } from 'react'
import { PERMISSION, WidgetsServiceFindAllQueryOptions } from '@/services'
import { Widget, WidgetQueryFallback } from '@/components'
import { usePermissions, useQueryProcessedPayments } from '@/hooks'
import { If } from '@/components/@misc'
import { useIntl } from 'react-intl'
import { prettyPrintCasedWords } from '@/utils'
import { HOME_PAGE_OBJECT_TYPE_COLORS } from './ProcessedPaymentsWidget.const'
import { chain } from 'lodash'

export const ProcessedPaymentsWidget: React.FC<WidgetsServiceFindAllQueryOptions> = memo((props) => {
    const intl = useIntl()
    const query = useQueryProcessedPayments(props)
    const chartData = useMemo(() => {
        const datasetLabelFormatter = (value: string) => {
            return intl.formatMessage(
                {
                    id: 'app.common.pluralization.label'
                },
                {
                    count: 2,
                    singular: prettyPrintCasedWords(value)
                }
            )
        }

        return transformWidgetTimeSeriesToChartData(
            query.data,
            intl,
            {
                dataset: datasetLabelFormatter
            },
            HOME_PAGE_OBJECT_TYPE_COLORS
        )
    }, [query, intl])
    const isDataEmpty = useMemo(() => {
        const queryDataWrapper = chain(query.data)

        return (
            queryDataWrapper.isNil().value() ||
            queryDataWrapper
                .get('data_series')
                .flatMap(({ data }) => data)
                .every((value) => value === 0)
                .value()
        )
    }, [query])
    const title = useMemo(
        () =>
            intl.formatMessage({
                id: 'app.widgets.payments_processed.title'
            }),
        [intl]
    )
    const { hasPermission } = usePermissions()
    const hasViewProcessedPaymentsPermission = useMemo(() => {
        return hasPermission(PERMISSION.HOME_VIEW_PROCESSED_PAYMENTS)
    }, [hasPermission])

    return (
        <If condition={hasViewProcessedPaymentsPermission}>
            <Widget title={title}>
                <WidgetQueryFallback isLoading={query.isLoading} isDataEmpty={isDataEmpty}>
                    <ChartBarVerticalStack data={chartData} showLegend={true} />
                </WidgetQueryFallback>
            </Widget>
        </If>
    )
})
