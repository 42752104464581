import { EntityDetail, EntityDetailRowCustomizers, QueryFallback, RestrictedSection } from '@/components'
import { useQueryFindByIdExpectedPayment } from '@/hooks'
import { PageLayout } from '@/layouts'
import { getAccountRowCustomizers } from '@/pages'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { Account } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { EXPECTED_PAYMENT_COUNTERPARTY_DETAIL_ROWS } from './ExpectedPaymentCounterparty.page.const'

export const ExpectedPaymentCounterpartyPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdExpectedPayment(uuid)
    const rowCustomizers = useMemo<EntityDetailRowCustomizers<Account>>(getAccountRowCustomizers, [])

    return (
        <PageLayout>
            <RestrictedSection
                feature={ENTITY_FEATURE.RECONCILIATION}
                permission={PERMISSION.PAYMENTS_VIEW_EXPECTED_PAYMENTS}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.expected_payment}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isEmpty(query?.data)}
                    isDataEmpty={isEmpty(query?.data)}>
                    <EntityDetail<Account>
                        data={query.data?.counterparty_account}
                        rows={EXPECTED_PAYMENT_COUNTERPARTY_DETAIL_ROWS}
                        rowCustomizers={rowCustomizers}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
