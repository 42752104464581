import { TableHeaderActionProps } from '@/components'
import { FormattedDate, Separator, SeparatorVariant } from '@/components/@misc'
import { useMutationResendByIdEvent, usePermissions, useQueryFindByIdEvent, useToasts } from '@/hooks'
import { PageHeaderLayout } from '@/layouts'
import { TOAST_VARIANTS } from '@/providers'
import { PERMISSION } from '@/services'
import { Box, BoxProps, Text } from '@chakra-ui/react'
import { isEventDelivered, Uuid } from '@webapps/numeral-ui-core'
import React, { ReactNode, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { getNumeralEventTopicAndTypePattern } from './EventDetails.header.utils'

export const EventDetailsHeader: React.FC<BoxProps> = (props) => {
    const { uuid } = useParams()
    const intl = useIntl()
    const { hasPermission } = usePermissions()
    const { onAdd } = useToasts()
    const eventResendMutation = useMutationResendByIdEvent(uuid as Uuid, {
        onSuccess(response) {
            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                isClosable: true,
                title: intl.formatMessage({
                    id: 'app.developers.events.header.actions.resend.success.toast.title'
                }),
                description: intl.formatMessage({
                    id: 'app.developers.events.header.actions.resend.success.toast.description'
                })
            })
        }
    })

    const query = useQueryFindByIdEvent(uuid)

    const title = useMemo<ReactNode>(() => {
        if (!query.data) {
            return null
        }

        const objectId = query.data.id
        const displayDate = query.data.created_at
        const hasTopicAndType = globalThis.Boolean(query.data.topic && query.data.type)

        return (
            <>
                {objectId && (
                    <>
                        <Text as="span" whiteSpace="nowrap">
                            {objectId}
                        </Text>
                        <br />
                    </>
                )}

                {hasTopicAndType && (
                    <>
                        <Separator variant={SeparatorVariant.Bullet} />
                        {getNumeralEventTopicAndTypePattern(query.data)}
                    </>
                )}
                {displayDate && (
                    <>
                        <Separator variant={SeparatorVariant.Bullet} />
                        <FormattedDate value={displayDate} />
                    </>
                )}
            </>
        )
    }, [query])

    const actions = useMemo(() => {
        const actions: TableHeaderActionProps[] = []

        const isResendAvailable = isEventDelivered(query.data)

        if (hasPermission(PERMISSION.PAYMENTS_CREATE_RETURNS) && isResendAvailable) {
            actions.push({
                onClick() {
                    eventResendMutation.mutate()
                },
                isDisabled: !isResendAvailable,
                children: intl.formatMessage({ id: 'app.developers.events.header.actions.resend.label' })
            })
        }

        return actions
    }, [query, hasPermission, intl])

    return (
        <Box {...props}>
            <PageHeaderLayout title={title} isLoading={query.isLoading} actions={actions} />
        </Box>
    )
}
