import { QueryFallback, RestrictedSection } from '@/components'
import {
    useMutationDenyCounterpartyAccount,
    useNavigationRoutes,
    useQueryFindByIdCounterpartyAccount,
    useToasts
} from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { TOAST_VARIANTS, updateUUIDRelativeActionPathname } from '@/providers'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import {
    ApiObjectTypeSchema,
    CounterpartyAccountStatus,
    CounterpartyAccountStatusSchema
} from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { CounterpartyAccountDenyForm } from './@components'

export function canAcceptOrDenyCounterpartyAccountByStatus(status?: CounterpartyAccountStatus) {
    return status === CounterpartyAccountStatusSchema.enum.pending_approval
}

export const CounterpartyAccountDenyPage: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const navigate = useNavigate()
    const location = useLocation()
    const { relativePaths } = useNavigationRoutes()

    const title = useMemo<string>(
        () => intl.formatMessage({ id: 'app.counterparties.counterparty_accounts.deny.title' }),
        [intl]
    )
    const { onAdd } = useToasts()
    const query = useQueryFindByIdCounterpartyAccount(uuid)
    const mutation = useMutationDenyCounterpartyAccount(uuid, {
        onSuccess(response) {
            const { COUNTERPARTIES } = relativePaths
            const path = updateUUIDRelativeActionPathname(location.pathname, COUNTERPARTIES.DETAILS)

            navigate(path)

            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: <FormattedMessage id="app.counterparties.counterparty_accounts.deny.success.toast.title" />,
                description: (
                    <FormattedMessage id="app.counterparties.counterparty_accounts.deny.success.toast.description" />
                )
            })
        }
    })

    const isDisabled = useMemo<boolean>(() => {
        const canAcceptOrDeny = canAcceptOrDenyCounterpartyAccountByStatus(query.data?.status)
        const isLoading = query.isLoading || mutation.isPending
        return !canAcceptOrDeny || isLoading
    }, [query, mutation])

    return (
        <PageLayout className="CounterpartyAccountDeny">
            <PageHeaderLayout title={title} />
            <RestrictedSection
                feature={ENTITY_FEATURE.COUNTERPARTY}
                permission={PERMISSION.COUNTERPARTIES_APPROVE_COUNTERPARTY_ACCOUNTS}>
                <QueryFallback
                    objectType={ApiObjectTypeSchema.enum.counterparty_account}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isEmpty(query?.data)}
                    isDataEmpty={isEmpty(query?.data)}>
                    <CounterpartyAccountDenyForm
                        onSubmit={mutation.mutate}
                        loading={mutation.isPending}
                        disabled={isDisabled}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
