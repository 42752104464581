import { QueryFallback, RestrictedSection, Table, useTableRowNavigation } from '@/components'
import { useNavigationRoutes, useQueryFindAllVirtualAccounts } from '@/hooks'
import { PageLayout } from '@/layouts'
import { VIRTUAL_ACCOUNT_DOCUMENTATION_LINK } from '@/pages/Counterparties/VirtualAccounts/VirtualAccounts.page.const'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import { ApiObjectTypeSchema, VirtualAccount } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
    CONNECTED_ACCOUNT_VIRTUAL_ACCOUNTS_TABLE_COLUMN_CUSTOMIZERS,
    CONNECTED_ACCOUNT_VIRTUAL_ACCOUNTS_TABLE_STATIC_STATE
} from './ConnectedAccountVirtualAccounts.page.const'

export const ConnectedAccountVirtualAccountsPage = () => {
    const { uuid } = useParams()
    const { paths } = useNavigationRoutes()

    const query = useQueryFindAllVirtualAccounts({ connected_account_id: uuid })
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])

    const onTableRowClick = useTableRowNavigation<VirtualAccount>(
        'id',
        `${paths.COUNTERPARTIES.VIRTUAL_ACCOUNTS}`,
        true
    )

    return (
        <PageLayout>
            <RestrictedSection
                feature={ENTITY_FEATURE.COUNTERPARTY}
                permission={PERMISSION.COUNTERPARTIES_VIEW_VIRTUAL_ACCOUNTS}>
                <QueryFallback
                    objectType={ApiObjectTypeSchema.enum.virtual_account}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError}
                    isDataEmpty={isDataEmpty}
                    customEmptyStateDescriptionKey="app.accounts.connected_accounts.virtual_accounts.empty_state.description"
                    learnMoreLinkUrl={VIRTUAL_ACCOUNT_DOCUMENTATION_LINK}>
                    <Table<VirtualAccount>
                        data={data}
                        onRowClick={onTableRowClick}
                        onScrollToBottom={query.fetchNextPage}
                        isLoading={query.hasNextPage}
                        columnCustomizers={CONNECTED_ACCOUNT_VIRTUAL_ACCOUNTS_TABLE_COLUMN_CUSTOMIZERS}
                        state={CONNECTED_ACCOUNT_VIRTUAL_ACCOUNTS_TABLE_STATIC_STATE}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
