import { QueryFallback, RestrictedSection } from '@/components'
import {
    useMutationCreateReturnRequest,
    useNavigationRoutes,
    useQueryFindByIdConnectedAccount,
    useQueryFindByIdPaymentOrder
} from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, isPaymentOrderReturnAvailable, PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { PaymentOrderReturnForm } from './@components'

export const PaymentOrderReturnPage: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const navigate = useNavigate()
    const { paths, relativePaths } = useNavigationRoutes()
    const title = useMemo<string>(
        () =>
            intl.formatMessage({
                id: 'app.payments.payment_orders.return.title'
            }),
        [intl]
    )
    const query = useQueryFindByIdPaymentOrder(uuid)
    const queryConnectedAccount = useQueryFindByIdConnectedAccount(query.data?.connected_account_id, {
        enabled: globalThis.Boolean(query.data?.connected_account_id)
    })
    const mutation = useMutationCreateReturnRequest({
        onSuccess(response) {
            if (!response?.id) {
                return
            }

            const { PAYMENTS } = paths
            const { DETAILS } = relativePaths.PAYMENTS
            const path = [PAYMENTS.RETURN_REQUESTS, response.id, DETAILS].join('/')

            navigate(path)
        }
    })
    const isDisabled = useMemo<boolean>(() => {
        const hasReturnAvailable = isPaymentOrderReturnAvailable(query.data, queryConnectedAccount.data)
        return !hasReturnAvailable || query.isLoading || mutation.isPending
    }, [query, queryConnectedAccount, mutation])

    return (
        <PageLayout className="PaymentOrderReturn">
            <PageHeaderLayout title={title} />
            <RestrictedSection
                feature={ENTITY_FEATURE.PAYMENT_ORDER}
                permission={PERMISSION.PAYMENTS_CREATE_RETURN_REQUESTS}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.payment_order}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isEmpty(query?.data)}
                    isDataEmpty={isEmpty(query?.data)}>
                    <PaymentOrderReturnForm
                        data={query.data}
                        onSubmit={mutation.mutate}
                        loading={mutation.isPending}
                        disabled={isDisabled}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
