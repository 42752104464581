import {
    EntityDetailCellHolderAddress,
    EntityDetailCellTextWithCopyButton,
    EntityDetailRow,
    EntityDetailRowCustomizers,
    EntityRowCustomizerDef
} from '@/components'
import { BankCode, FormattedTextIntl } from '@/components/@misc'
import { hasUltimateOriginator } from '@/services'
import { hasAccountType } from '@/services/@commons'
import { accountNumberFormatter, whitespaceFormatter } from '@/utils/@formatters'
import {
    Account,
    ConnectedAccount,
    DirectDebitMandate,
    ExpectedPayment,
    IncomingPayment,
    PaymentOrder,
    UltimateOriginator
} from '@webapps/numeral-ui-core'
import { get } from 'lodash'
import { CounterpartyOrganizationIdentification, CounterpartyRelatedObjects } from './@components'
import { COUNTERPARTY_ACCOUNT_DETAIL_ROWS } from './Counterparty.page.const'
import { CounterpartyAccountCustomRows, CounterpartyCustomRows } from './Counterparty.page.types'

export function getCounterpartyRowCustomizers<T = void>(
    accountKey: 'originating_account' | 'receiving_account',
    item?: PaymentOrder | IncomingPayment | DirectDebitMandate,
    connectedAccount?: ConnectedAccount
): EntityRowCustomizerDef<Account> {
    const account: Account = get(item as PaymentOrder, accountKey)

    return {
        nested: true,
        label(item) {
            return <FormattedTextIntl messageId={`app.page.details.fields.${accountKey}.alternative.label`} />
        },
        rows: getAccountDetailRows(account),
        rowCustomizers: getAccountRowCustomizers(accountKey, item, connectedAccount)
    }
}

export function getAccountDetailRows(connectedAccount?: Account): EntityDetailRow<CounterpartyAccountCustomRows>[] {
    const rows = [...COUNTERPARTY_ACCOUNT_DETAIL_ROWS]

    if (connectedAccount?.creditor_identifier) {
        rows.splice(3, 0, 'creditor_identifier')
    }

    return rows
}

export function getAccountRowCustomizers(
    accountKey?: 'originating_account' | 'receiving_account',
    payment?: PaymentOrder | IncomingPayment | ExpectedPayment | DirectDebitMandate,
    connectedAccount?: ConnectedAccount
): EntityDetailRowCustomizers<CounterpartyAccountCustomRows> {
    return {
        account_number: {
            cell(counterpartyAccount) {
                const formattedValue = accountNumberFormatter(counterpartyAccount.account_number)
                return <EntityDetailCellTextWithCopyButton value={formattedValue} formatter={whitespaceFormatter} />
            }
        },
        bank_code: {
            cell(counterpartyAccount) {
                return <BankCode value={counterpartyAccount.bank_code} />
            }
        },
        holder_address: {
            cell(counterpartyAccount) {
                return <EntityDetailCellHolderAddress value={counterpartyAccount.holder_address} />
            }
        },
        related_objects: {
            cell() {
                return (
                    <CounterpartyRelatedObjects
                        accountKey={accountKey}
                        value={payment}
                        connectedAccount={connectedAccount}
                    />
                )
            }
        }
    }
}

function getUltimateOriginatorRowCustomizers(): EntityDetailRowCustomizers<UltimateOriginator> {
    return {
        holder_address: {
            cell(counterpartyAccount) {
                return <EntityDetailCellHolderAddress value={counterpartyAccount.holder_address} />
            }
        },
        organization_identification: {
            cell(counterpartyAccount) {
                return (
                    <CounterpartyOrganizationIdentification
                        organizationIdentification={counterpartyAccount.organization_identification}
                    />
                )
            }
        }
    }
}

export function getPaymentCounterpartyDetailRowCustomizers(
    item?: PaymentOrder | IncomingPayment | DirectDebitMandate,
    connectedAccount?: ConnectedAccount
): EntityDetailRowCustomizers<CounterpartyCustomRows> {
    const customizers: EntityDetailRowCustomizers<any> = Object.create(null)
    const originatingAccountKey = 'originating_account'
    const ultimateOriginatorKey = 'ultimate_originator'
    const receivingAccountKey = 'receiving_account'

    if (hasAccountType(item, originatingAccountKey)) {
        customizers[originatingAccountKey] = getCounterpartyRowCustomizers<NonNullable<typeof item>>(
            originatingAccountKey,
            item,
            connectedAccount
        )
    }

    if (hasUltimateOriginator(item)) {
        customizers[ultimateOriginatorKey] = {
            nested: true,
            rows: ['holder_name', 'organization_identification', 'holder_address'],
            rowCustomizers: getUltimateOriginatorRowCustomizers()
        }
    }

    if (hasAccountType(item, receivingAccountKey)) {
        customizers[receivingAccountKey] = getCounterpartyRowCustomizers<NonNullable<typeof item>>(
            receivingAccountKey,
            item
        )
    }

    return customizers
}

export function getPaymentCounterpartyDetailRows(item?: PaymentOrder | IncomingPayment | DirectDebitMandate) {
    const rows: EntityDetailRow<CounterpartyCustomRows>[] = []

    if (hasAccountType(item, 'originating_account')) {
        rows.push('originating_account')
    }

    if (hasUltimateOriginator(item)) {
        rows.push('ultimate_originator')
    }

    if (hasAccountType(item, 'receiving_account')) {
        rows.push('receiving_account')
    }

    return rows
}
