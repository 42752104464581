import {
    FilterInputs,
    TableCellShortDate,
    TableCellText,
    TableColumnCustomizers,
    TableStateWithEntity
} from '@/components'
import { CounterpartyAccountStatusInput, CountryInput, DateInput, TextInput } from '@/components/@inputs'
import { BankCode, CounterpartyAccountVerificationStatus, StatusLabel } from '@/components/@misc'
import { COLUMN_WIDTH_RATIO, DOCUMENTATION_BASE_URL, MIN_COLUMN_WIDTH } from '@/constants'
import { QueryParamQuickFilters } from '@/hooks'
import { accountNumberFormatter } from '@/utils/@formatters'
import {
    CounterpartyAccount,
    CounterpartyAccountsServiceFindAllQueryOptions,
    CounterpartyAccountStatusSchema
} from '@webapps/numeral-ui-core'

export const COUNTERPARTY_ACCOUNT_DOCUMENTATION_LINK = `${DOCUMENTATION_BASE_URL}/counterparty-account-object`

export const COUNTERPARTY_ACCOUNT_TABLE_STATIC_STATE: TableStateWithEntity<CounterpartyAccount> = {
    columnOrder: Object.freeze([
        'created_at',
        'name',
        'holder_name',
        'account_number',
        'bank_code',
        'status',
        'account_verification'
    ])
}

export const COUNTERPARTY_ACCOUNT_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<CounterpartyAccount> = {
    created_at: {
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        size: COLUMN_WIDTH_RATIO.SHORT_DATE,
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    name: {
        cell(info) {
            return <TableCellText value={info.getValue()} />
        }
    },
    holder_name: {
        cell(info) {
            return <TableCellText value={info.getValue()} />
        }
    },
    account_number: {
        minSize: MIN_COLUMN_WIDTH.ACCOUNT_NUMBER,
        cell(info) {
            const formattedValue = accountNumberFormatter(info.getValue())

            return <TableCellText value={formattedValue} />
        }
    },
    bank_code: {
        cell(info) {
            return <BankCode value={info.getValue()} />
        }
    },
    status: {
        minSize: MIN_COLUMN_WIDTH.SHORT_STATUS,
        cell(info) {
            return <StatusLabel value={info.getValue()} />
        }
    },
    account_verification: {
        cell(info) {
            return <CounterpartyAccountVerificationStatus accountVerification={info.getValue()} />
        }
    }
}

export const COUNTERPARTY_ACCOUNT_FILTERS: FilterInputs<CounterpartyAccountsServiceFindAllQueryOptions> = {
    start_date: <DateInput name="start_date" />,
    end_date: <DateInput name="end_date" />,
    holder_name: <TextInput name="holder_name" />,
    account_number: <TextInput name="account_number" />,
    country: <CountryInput name="country" isPlaceholderDisplayed={true} />,
    status: <CounterpartyAccountStatusInput name="status" />
}

export const COUNTERPARTY_ACCOUNT_QUICK_FILTERS: QueryParamQuickFilters<CounterpartyAccountsServiceFindAllQueryOptions> =
    {
        status: [CounterpartyAccountStatusSchema.enum.pending_approval]
    }
