import {
    ApiObjectType,
    CurrencyCode,
    DateISOFormat,
    IncomingPaymentStatus,
    List,
    PaymentOrderStatus,
    PaymentType,
    ReturnStatus,
    SepaReturnReason,
    Uuid
} from '@webapps/numeral-ui-core'
import { Payment } from '@/types'

export enum WidgetSeriesReservedLabels {
    Other = 'OTHER_LABEL',
    Empty = ''
}

export interface WidgetTimeSeries<T = string> {
    time_series: string[]
    data_series: {
        label: T
        data: number[]
    }[]
}

export enum WidgetsServiceGroupByOptions {
    Day = 'day',
    Week = 'week',
    Month = 'month',
    Quarter = 'quarter'
}

export const enum WidgetsServiceLookBehindOffsets {
    NO_DAY = 0,
    ONE_DAY = 1,
    SEVEN_DAYS = 7,
    SIX_MONTHS = 182 // Non-leap year
}

export enum WIDGET_TYPE {
    // First phase
    ProcessedPaymentsTotalNumber = 'processed_payments_total_number',
    ProcessedPaymentsTotalAmounts = 'processed_payments_total_amounts',
    ProcessedPayments = 'processed_payments',
    PaymentCounters = 'payment_counters',
    // Second phase:
    Balances = 'balances',
    Reconciliations = 'reconciliations',
    PaymentOrderReturns = 'payment_order_returns',
    LatestEvents = 'latest_events'
}

export type WidgetsServiceFindAllQueryOptions = Partial<{
    start_date: DateISOFormat
    end_date: DateISOFormat
    group_by: WidgetsServiceGroupByOptions
    currency: CurrencyCode
}>

export type WidgetsServicePaymentCountersQueryOptions = WidgetsServiceFindAllQueryOptions & {
    payment_type: PaymentType
}

export type WidgetsServiceBalancesQueryOptions = WidgetsServiceFindAllQueryOptions & {
    bank_name?: string
}

export type WidgetsServiceReconciliationQueryOptions = Pick<WidgetsServiceFindAllQueryOptions, 'currency'>
export type WidgetsServicePaymentOrderReturnsQueryOptions = Pick<WidgetsServiceFindAllQueryOptions, 'currency'>

export interface ProcessedPaymentsTotalNumber {
    total_number: number
}

export interface ProcessedPaymentsTotalAmount {
    currency: CurrencyCode
    total_amount: number
}

export type ProcessedPayments = WidgetTimeSeries<Payment>

export type Balances = WidgetTimeSeries<Uuid | WidgetSeriesReservedLabels.Other>

export type ReconciliationItem = {
    object: ApiObjectType
    total_reconciled: number
    reconciled_rate: number // in percentage
}
export type Reconciliations = {
    automation_rate: number // in percentage
} & List<ReconciliationItem>

export type PaymentOrderReturnItem = {
    label: SepaReturnReason | WidgetSeriesReservedLabels.Other | WidgetSeriesReservedLabels.Empty /*no reason*/
    rate: number // in percentage
}
export type PaymentOrderReturns = {
    return_rate: number // in percentage
} & List<PaymentOrderReturnItem>

export interface PaymentCounters {
    status: PaymentOrderStatus | IncomingPaymentStatus | ReturnStatus
    last_seven_days: number
    today: number
}
