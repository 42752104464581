import { TableColumnCustomizers, TableStateWithEntity } from '@/components'
import { PaymentCounters, WidgetsServicePaymentCountersQueryOptions } from '@/services'
import { PaymentTypeSchema } from '@webapps/numeral-ui-core'
import { Text } from '@chakra-ui/react'
import { prettyPrintCasedWords } from '@/utils'
import { HOME_PAGE_STYLE_CONFIGURATION } from '@/pages/Home/Home.page.const'

export const PAYMENT_COUNTERS_PRESELECTED_FILTERS: Partial<WidgetsServicePaymentCountersQueryOptions> = {
    payment_type: PaymentTypeSchema.enum.payment_order
}

export const PAYMENT_COUNTERS_AVAILABLE_OPTIONS = [
    PaymentTypeSchema.enum.payment_order,
    PaymentTypeSchema.enum.incoming_payment,
    PaymentTypeSchema.enum.return
]

export const PAYMENT_COUNTERS_WIDGET_TABLE_STATIC_STATE: TableStateWithEntity<PaymentCounters> = {
    columnOrder: ['status', 'last_seven_days', 'today']
}

export const PAYMENT_COUNTERS_WIDGET_COLUMN_CUSTOMIZERS: TableColumnCustomizers<PaymentCounters> = {
    status: {
        header() {
            return null
        },
        cell(info) {
            const formattedValue = prettyPrintCasedWords(info.getValue())
            return (
                <Text
                    color="gray.500"
                    fontSize={HOME_PAGE_STYLE_CONFIGURATION.FONT_SIZE}
                    title={formattedValue}
                    aria-label={formattedValue}>
                    {formattedValue}
                </Text>
            )
        }
    }
}
