import { QueryFallback, RestrictedSection } from '@/components'
import { useMutationDenyByIdInquiry, useNavigationRoutes, useQueryFindByIdInquiry } from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { canDenyInquiry } from '@/pages/Payments/Inquiries/Inquiries.page.utils'
import { updateUUIDRelativeActionPathname } from '@/providers'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { InquiryDenyForm } from './@components'

export const InquiryDenyPage: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const navigate = useNavigate()
    const location = useLocation()
    const { relativePaths } = useNavigationRoutes()
    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.payments.inquiries.deny.title' }), [intl])
    const query = useQueryFindByIdInquiry(uuid)
    const mutation = useMutationDenyByIdInquiry(uuid, {
        onSuccess() {
            const { PAYMENTS } = relativePaths
            const path = updateUUIDRelativeActionPathname(location.pathname, PAYMENTS.DETAILS)

            navigate(path)
        }
    })
    const isDisabled = useMemo<boolean>(() => {
        const canDeny = canDenyInquiry(query.data)
        const isLoading = query.isLoading || mutation.isPending
        return !canDeny || isLoading
    }, [query, mutation])

    return (
        <PageLayout className="InquiryDeny">
            <PageHeaderLayout title={title} />
            <RestrictedSection feature={ENTITY_FEATURE.INQUIRY} permission={PERMISSION.PAYMENTS_APPROVE_INQUIRIES}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.inquiry}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isEmpty(query?.data)}
                    isDataEmpty={isEmpty(query?.data)}>
                    <InquiryDenyForm onSubmit={mutation.mutate} loading={mutation.isPending} disabled={isDisabled} />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
