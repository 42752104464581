import { QueryFallback, RestrictedSection, Table, TableHeader, useTableRowNavigation } from '@/components'
import { useNavigationRoutes, useQueryFindAllConnectedAccountsExpanded, useQueryParams } from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ConnectedAccountExpanded, PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import { ApiObjectTypeSchema, ConnectedAccountsServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import {
    CONNECTED_ACCOUNTS_FILTERS,
    CONNECTED_ACCOUNTS_TABLE_COLUMN_CUSTOMIZERS,
    CONNECTED_ACCOUNTS_TABLE_STATIC_STATE
} from './ConnectedAccounts.page.const'

export const ConnectedAccountsPage: React.FC = () => {
    const intl = useIntl()
    const { relativePaths } = useNavigationRoutes()
    const { search, filterBy } = useQueryParams('search', {
        filterBy: {
            configuration: {
                filters: CONNECTED_ACCOUNTS_FILTERS
            }
        }
    })

    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.accounts.connected_accounts.title' }), [intl])

    const query = useQueryFindAllConnectedAccountsExpanded({ ...search.parsedState, ...filterBy.parsedState })
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])

    const onTableRowClick = useTableRowNavigation<ConnectedAccountExpanded>('id', relativePaths.ACCOUNTS.DETAILS)

    return (
        <PageLayout>
            <PageHeaderLayout title={title} showBackButton={false} />
            <RestrictedSection permission={PERMISSION.ACCOUNTS_VIEW_CONNECTED_ACCOUNTS}>
                <TableHeader<ConnectedAccountsServiceFindAllQueryOptions>
                    objectType={ApiObjectTypeExtendedSchema.enum.connected_account}
                    isLoading={query.isLoading}
                    filterBy={filterBy}
                    search={search}
                />
                <QueryFallback
                    objectType={ApiObjectTypeSchema.enum.connected_account}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError}
                    isDataEmpty={isDataEmpty}
                    isFilteringOrSearching={!!search?.isActive || !!filterBy?.isActive}>
                    <Table<ConnectedAccountExpanded>
                        data={data}
                        onRowClick={onTableRowClick}
                        onScrollToBottom={query.fetchNextPage}
                        isLoading={query.hasNextPage}
                        state={CONNECTED_ACCOUNTS_TABLE_STATIC_STATE}
                        columnCustomizers={CONNECTED_ACCOUNTS_TABLE_COLUMN_CUSTOMIZERS}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
