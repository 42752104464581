import { EntityDetail, QueryFallback, RestrictedSection } from '@/components'
import { If } from '@/components/@misc'
import { usePermissions, useQueryFindByIdCounterpartyAccount } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { ApiObjectTypeSchema, CounterpartyAccount } from '@webapps/numeral-ui-core'
import { chain, isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { CounterpartyAccountVerificationFooter } from './@components'
import {
    COUNTERPARTY_ACCOUNT_VERIFICATIONS_ROW_CUSTOMIZERS,
    COUNTERPARTY_ACCOUNT_VERIFICATIONS_ROWS
} from './CounterpartyAccountVerifications.page.const'
import { CounterpartyAccountVerificationCustomRows } from './CounterpartyAccountVerifications.types'

export const CounterpartyAccountVerificationsPage: React.FC = () => {
    const { uuid } = useParams()
    const { hasPermission } = usePermissions()
    const query = useQueryFindByIdCounterpartyAccount(uuid)
    const canVerifyCounterpartyAccount = useMemo<boolean>(() => {
        const hasVerifyCounterpartyAccountsPermission = hasPermission(
            PERMISSION.COUNTERPARTIES_VERIFY_COUNTERPARTY_ACCOUNTS
        )
        const hasNoAccountVerification = chain(query.data?.account_verification).isNull().value()

        return hasVerifyCounterpartyAccountsPermission && hasNoAccountVerification
    }, [query, hasPermission])

    return (
        <PageLayout>
            <RestrictedSection
                feature={ENTITY_FEATURE.COUNTERPARTY_ACCOUNT_VERIFICATION}
                permission={PERMISSION.COUNTERPARTIES_VIEW_COUNTERPARTY_ACCOUNTS}>
                <QueryFallback
                    objectType={ApiObjectTypeSchema.enum.counterparty_account}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isEmpty(query?.data)}
                    isDataEmpty={isEmpty(query?.data)}>
                    <EntityDetail<CounterpartyAccount, CounterpartyAccountVerificationCustomRows>
                        data={query.data}
                        rows={COUNTERPARTY_ACCOUNT_VERIFICATIONS_ROWS}
                        rowCustomizers={COUNTERPARTY_ACCOUNT_VERIFICATIONS_ROW_CUSTOMIZERS}
                    />
                    <If condition={canVerifyCounterpartyAccount}>
                        <CounterpartyAccountVerificationFooter counterpartyAccountId={query.data?.id} />
                    </If>
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
