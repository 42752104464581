import { EntityDetail, QueryFallback, RestrictedSection } from '@/components'
import { useQueryFindByIdVirtualAccount } from '@/hooks/api'
import { PageLayout } from '@/layouts'
import { VirtualAccountCustomRows } from '@/pages/Counterparties/VirtualAccounts/VirtualAccountDetails/VirtualAccountDetails.page.types'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { ApiObjectTypeSchema, VirtualAccount } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import {
    VIRTUAL_ACCOUNT_DETAILS_ROW_CUSTOMIZERS,
    VIRTUAL_ACCOUNT_DETAILS_ROWS
} from './VirtualAccountDetails.page.const'

export const VirtualAccountDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdVirtualAccount(uuid)

    return (
        <PageLayout>
            <RestrictedSection
                feature={ENTITY_FEATURE.COUNTERPARTY}
                permission={PERMISSION.COUNTERPARTIES_VIEW_VIRTUAL_ACCOUNTS}>
                <QueryFallback
                    objectType={ApiObjectTypeSchema.enum.virtual_account}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isEmpty(query?.data)}
                    isDataEmpty={isEmpty(query?.data)}>
                    <EntityDetail<VirtualAccount, VirtualAccountCustomRows>
                        data={query.data}
                        rows={VIRTUAL_ACCOUNT_DETAILS_ROWS}
                        rowCustomizers={VIRTUAL_ACCOUNT_DETAILS_ROW_CUSTOMIZERS}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
