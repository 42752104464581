import { Loading, WidgetProps, WidgetVariants } from '@/components'
import { useMemo } from 'react'
import { FlexProps } from '@chakra-ui/react'

export const WidgetLoading: React.FC<WidgetProps> = ({ variant }) => {
    const propsLoading = useMemo<FlexProps>(() => {
        const hasLiteWidgetVariant = variant === WidgetVariants.Lite

        return {
            marginY: hasLiteWidgetVariant ? '0' : '25%',
            height: '25%'
        }
    }, [variant])

    return <Loading {...propsLoading} />
}
