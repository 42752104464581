import { Flex, FlexProps, Spinner, Text } from '@chakra-ui/react'

interface LoadingProps extends FlexProps {
    message?: string
}

export const Loading: React.FC<LoadingProps> = ({ message, ...props }) => {
    return (
        <Flex height="100%" justifyContent="center" {...props}>
            <Flex direction="column" justifyContent="center" alignItems="center" alignSelf="center">
                <Spinner />
                {message && <Text marginY="16px">{message}</Text>}
            </Flex>
        </Flex>
    )
}
