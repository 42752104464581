import {
    EntityDetail,
    EntityDetailRow,
    EntityDetailRowCustomizers,
    QueryFallback,
    RestrictedSection
} from '@/components'
import { useQueryFindByIdDirectDebitMandate } from '@/hooks'
import { PageLayout } from '@/layouts'
import {
    CounterpartyCustomRows,
    getPaymentCounterpartyDetailRowCustomizers,
    getPaymentCounterpartyDetailRows
} from '@/pages/Common'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { ApiObjectTypeSchema, DirectDebitMandate } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

export const DirectDebitMandatesCounterpartiesPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdDirectDebitMandate(uuid)

    const rows = useMemo<EntityDetailRow<CounterpartyCustomRows>[]>(() => {
        return getPaymentCounterpartyDetailRows(query.data)
    }, [query])
    const rowCustomizers = useMemo<EntityDetailRowCustomizers<CounterpartyCustomRows>>(() => {
        return getPaymentCounterpartyDetailRowCustomizers(query.data)
    }, [query])

    return (
        <PageLayout>
            <RestrictedSection
                feature={ENTITY_FEATURE.DIRECT_DEBIT_MANDATE}
                permission={PERMISSION.COUNTERPARTIES_VIEW_DIRECT_DEBIT_MANDATES}>
                <QueryFallback
                    objectType={ApiObjectTypeSchema.enum.counterparty}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError}
                    isDataEmpty={isEmpty(query?.data)}>
                    <EntityDetail<DirectDebitMandate> data={query.data} rows={rows} rowCustomizers={rowCustomizers} />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
