import {
    BaseHttpClientService,
    extractResponseData,
    List,
    Pagination,
    ServicesConfiguration
} from '@webapps/numeral-ui-core'
import { Bank } from './Banks.service.types'

export class BanksService extends BaseHttpClientService {
    get url() {
        return '/v1/banks'
    }

    async findAll(params: Partial<Pagination>, config?: ServicesConfiguration): Promise<List<Bank>> {
        return this.httpClient
            .get<List<Bank>>(this.url, {
                params,
                ...config
            })
            .then(extractResponseData)
            .catch(() => ({
                records: [
                    {
                        id: '1',
                        object_type: 'bank',
                        name: 'Bank example 1'
                    },
                    {
                        id: '2',
                        object_type: 'bank',
                        name: 'Bank example 2'
                    },
                    {
                        id: '3',
                        object_type: 'bank',
                        name: 'Bank example 3'
                    }
                ]
            }))
    }
}
