import { CollapseIcon, ExpandIcon } from '@/components/@icons'
import { MainNavigationFooterProps, MainNavigationSectionListItem } from '@/components/MainNavigation/@components'
import { Box } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

export const MainNavigationFooterCollapseItem: React.FC<Partial<MainNavigationFooterProps>> = ({
    isCollapsed,
    onToggleNavigation
}) => {
    const intl = useIntl()
    const title = useMemo<string>(() => {
        const messageId = isCollapsed ? 'app.nav.expand.label' : 'app.nav.collapse.label'
        return intl.formatMessage({
            id: messageId
        })
    }, [isCollapsed, intl])

    return (
        <MainNavigationSectionListItem onClick={onToggleNavigation} isCollapsed={isCollapsed} title={title}>
            {isCollapsed ? (
                <ExpandIcon />
            ) : (
                <Box flex="1" textAlign="left">
                    <CollapseIcon />
                    {title}
                </Box>
            )}
        </MainNavigationSectionListItem>
    )
}
