import { PERMISSION, WidgetsServiceFindAllQueryOptions } from '@/services'
import { useIntl } from 'react-intl'
import { FormattedAmount, If, Separator, SeparatorVariant } from '@/components/@misc'
import { Flex, Text } from '@chakra-ui/react'
import { memo, useMemo } from 'react'
import { Widget, WidgetQueryFallback, WidgetVariants } from '@/components'
import { usePermissions, useQueryProcessedPaymentsTotalAmounts } from '@/hooks'
import { chain, isEmpty } from 'lodash'
import { isLastIndex } from '@/utils'

export const TotalValuePaymentsProcessedWidget: React.FC<WidgetsServiceFindAllQueryOptions> = memo((props) => {
    const { hasPermission } = usePermissions()
    const intl = useIntl()
    const query = useQueryProcessedPaymentsTotalAmounts(props)
    const title = useMemo(
        () =>
            intl.formatMessage({
                id: 'app.widgets.total_value_payments_processed.title'
            }),
        [intl]
    )
    const isDataEmpty = useMemo(() => isEmpty(query.data?.records), [query])
    const hasViewProcessedPaymentsTotalAmountPermission = useMemo(() => {
        return hasPermission(PERMISSION.HOME_VIEW_PROCESSED_PAYMENTS_TOTAL_AMOUNTS)
    }, [hasPermission])

    return (
        <If condition={hasViewProcessedPaymentsTotalAmountPermission}>
            <Widget title={title} variant={WidgetVariants.Lite}>
                <WidgetQueryFallback
                    isLoading={query.isLoading}
                    isDataEmpty={isDataEmpty}
                    variant={WidgetVariants.Lite}>
                    <Flex>
                        {chain(query.data)
                            .get('records', [])
                            .map((item, index, array) => {
                                const formattedAmount = (
                                    <FormattedAmount amount={item.total_amount} currency={item.currency} />
                                ) as unknown as string

                                return (
                                    <Text fontSize="24px" aria-label={formattedAmount} key={index}>
                                        {formattedAmount}
                                        <If condition={!isLastIndex(index, array as any[])}>
                                            <Separator variant={SeparatorVariant.Bullet} />
                                        </If>
                                    </Text>
                                )
                            })
                            .value()}
                    </Flex>
                </WidgetQueryFallback>
            </Widget>
        </If>
    )
})
