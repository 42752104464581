import { PERMISSION } from '@/services'
import { BoxProps } from '@chakra-ui/react'

export const styleRoleUserPermissions: BoxProps = {
    borderWidth: '1px',
    borderRadius: 'var(--numeral-ui-primary-border-radius)',
    marginBottom: '8px',
    width: '100%'
}
export enum PERMISSIONS_GROUP_NAME {
    HOME = 'home',
    PAYMENTS = 'payments',
    ACCOUNTS = 'accounts',
    RECONCILIATIONS = 'reconciliations',
    COUNTERPARTIES = 'counterparties',
    FILES = 'files',
    SETTINGS = 'settings',
    DEVELOPERS = 'developers'
}

export const PERMISSION_GROUPING = Object.freeze({
    [PERMISSIONS_GROUP_NAME.HOME]: [
        PERMISSION.HOME_VIEW_PROCESSED_PAYMENTS_TOTAL_NUMBER,
        PERMISSION.HOME_VIEW_PROCESSED_PAYMENTS_TOTAL_AMOUNTS,
        PERMISSION.HOME_VIEW_PROCESSED_PAYMENTS,
        PERMISSION.HOME_VIEW_PAYMENT_COUNTERS,

        PERMISSION.HOME_VIEW_BALANCES,
        PERMISSION.HOME_VIEW_RECONCILIATIONS,
        PERMISSION.HOME_VIEW_RETURNS
    ],
    [PERMISSIONS_GROUP_NAME.PAYMENTS]: [
        PERMISSION.PAYMENTS_VIEW_PAYMENT_ORDERS,
        PERMISSION.PAYMENTS_CREATE_PAYMENT_ORDERS,
        PERMISSION.PAYMENTS_APPROVE_PAYMENT_ORDERS,
        PERMISSION.PAYMENTS_EDIT_PAYMENT_ORDERS,
        PERMISSION.PAYMENTS_VIEW_INCOMING_PAYMENTS,
        PERMISSION.PAYMENTS_CONFIRM_INCOMING_PAYMENTS,
        PERMISSION.PAYMENTS_VIEW_RETURNS,
        PERMISSION.PAYMENTS_CREATE_RETURNS,
        PERMISSION.PAYMENTS_VIEW_RETURN_REQUESTS,
        PERMISSION.PAYMENTS_CREATE_RETURN_REQUESTS,
        PERMISSION.PAYMENTS_APPROVE_RETURN_REQUESTS,
        PERMISSION.PAYMENTS_APPROVE_RETURNS,
        PERMISSION.PAYMENTS_VIEW_INQUIRIES,
        PERMISSION.PAYMENTS_APPROVE_INQUIRIES,
        PERMISSION.PAYMENTS_VIEW_EXPECTED_PAYMENTS,
        PERMISSION.PAYMENTS_CREATE_EXPECTED_PAYMENTS,
        PERMISSION.PAYMENTS_EDIT_EXPECTED_PAYMENTS,
        PERMISSION.PAYMENTS_VIEW_PAYMENT_CAPTURES,
        PERMISSION.PAYMENTS_CREATE_PAYMENT_CAPTURES,
        PERMISSION.PAYMENTS_EDIT_PAYMENT_CAPTURES
    ],
    [PERMISSIONS_GROUP_NAME.ACCOUNTS]: [
        PERMISSION.ACCOUNTS_VIEW_CONNECTED_ACCOUNTS,
        PERMISSION.ACCOUNTS_VIEW_TRANSACTIONS,
        PERMISSION.ACCOUNTS_EDIT_TRANSACTIONS,
        PERMISSION.ACCOUNTS_VIEW_BALANCES
    ],
    [PERMISSIONS_GROUP_NAME.RECONCILIATIONS]: [
        PERMISSION.RECONCILIATIONS_VIEW_RECONCILIATIONS,
        PERMISSION.RECONCILIATIONS_CONFIGURE_RECONCILIATIONS
    ],
    [PERMISSIONS_GROUP_NAME.COUNTERPARTIES]: [
        PERMISSION.COUNTERPARTIES_VIEW_COUNTERPARTIES,
        PERMISSION.COUNTERPARTIES_CREATE_COUNTERPARTIES,
        PERMISSION.COUNTERPARTIES_EDIT_COUNTERPARTIES,
        PERMISSION.COUNTERPARTIES_APPROVE_COUNTERPARTIES,
        PERMISSION.COUNTERPARTIES_VIEW_COUNTERPARTY_ACCOUNTS,
        PERMISSION.COUNTERPARTIES_CREATE_COUNTERPARTY_ACCOUNTS,
        PERMISSION.COUNTERPARTIES_EDIT_COUNTERPARTY_ACCOUNTS,
        PERMISSION.COUNTERPARTIES_APPROVE_COUNTERPARTY_ACCOUNTS,
        PERMISSION.COUNTERPARTIES_VERIFY_COUNTERPARTY_ACCOUNTS,
        PERMISSION.COUNTERPARTIES_VIEW_VIRTUAL_ACCOUNTS,
        PERMISSION.COUNTERPARTIES_CREATE_VIRTUAL_ACCOUNTS,
        PERMISSION.COUNTERPARTIES_EDIT_VIRTUAL_ACCOUNTS,
        PERMISSION.COUNTERPARTIES_VIEW_DIRECT_DEBIT_MANDATES,
        PERMISSION.COUNTERPARTIES_CREATE_DIRECT_DEBIT_MANDATES,
        PERMISSION.COUNTERPARTIES_EDIT_DIRECT_DEBIT_MANDATES
    ],
    [PERMISSIONS_GROUP_NAME.FILES]: [
        PERMISSION.FILES_VIEW_FILES,
        PERMISSION.FILES_APPROVE_FILES,
        PERMISSION.FILES_DOWNLOAD_FILES
    ],
    [PERMISSIONS_GROUP_NAME.SETTINGS]: [
        PERMISSION.SETTINGS_VIEW_USERS,
        PERMISSION.SETTINGS_INVITE_USERS,
        PERMISSION.SETTINGS_EDIT_USERS,
        PERMISSION.SETTINGS_DISABLE_USERS,
        PERMISSION.SETTINGS_VIEW_ROLES,
        PERMISSION.SETTINGS_CREATE_ROLES,
        PERMISSION.SETTINGS_CONFIGURE_ROLES,
        PERMISSION.SETTINGS_VIEW_AUDIT_TRAILS,
        PERMISSION.SETTINGS_VIEW_CUSTOM_FIELDS,
        PERMISSION.SETTINGS_CREATE_CUSTOM_FIELDS,
        PERMISSION.SETTINGS_EDIT_CUSTOM_FIELDS,
        PERMISSION.SETTINGS_DISABLE_CUSTOM_FIELDS,
        PERMISSION.SETTINGS_VIEW_RECONCILIATION_RULES,
        PERMISSION.SETTINGS_CONFIGURE_RECONCILIATION_RULES,
        PERMISSION.SETTINGS_VIEW_LEGAL_ENTITY,
        PERMISSION.SETTINGS_CONFIGURE_LEGAL_ENTITY,
        PERMISSION.SETTINGS_VIEW_BILLING,
        PERMISSION.SETTINGS_CONFIGURE_BILLING,
        PERMISSION.SETTINGS_EXPORT_INVOICES,
        PERMISSION.SETTINGS_VIEW_APPROVAL_RULES,
        PERMISSION.SETTINGS_CONFIGURE_APPROVAL_RULES
    ],
    [PERMISSIONS_GROUP_NAME.DEVELOPERS]: [
        PERMISSION.DEVELOPERS_VIEW_API_KEYS,
        PERMISSION.DEVELOPERS_CONFIGURE_API_KEYS,
        PERMISSION.DEVELOPERS_VIEW_WEBHOOKS,
        PERMISSION.DEVELOPERS_CONFIGURE_WEBHOOKS,
        PERMISSION.DEVELOPERS_VIEW_EVENTS,
        PERMISSION.DEVELOPERS_RESEND_EVENTS,
        PERMISSION.DEVELOPERS_VIEW_LOGS,
        PERMISSION.DEVELOPERS_TRIGGER_BANK_SIMULATOR
    ]
})
